<template>
  <div id="app">
    <div class="fluid-container text-center headdiv">
      <div class="card-header header-title">
        <H3>COVID19-Trace</H3>
      </div>
      <div class="container bodydiv">
        <div v-if="cur_page == 1" class="row">
          <div class="col">
            <div class="row">
              <div class="col text-center">
                <br />
                <h4>{{ store_name }}</h4>
              </div>
            </div>
            <div class="row">
              <div class="col text-left">
                According to ORDER of The Provincial Health Officer Public
                Health Act, restaurant must collect the name, telephone number
                or email address, of at least one member of every party of
                patrons and retain it ort 30 days in case there is a need for
                contact tracing on the part of the medical health officer, which
                case you must provide the information to the medical health
                officer. <br />
                Your cooperation is appreciated!
                <br /><br />
                根据省卫生官员公共卫生法令，餐厅必须收集每位顾客至少一名成员的姓名，电话号码或者郵件地址.
                您的信息將會嚴格保密並只保留30天，在必要情況下您的信息提供给政府医疗保健官。
                <br />
                感谢您的合作！
              </div>
            </div>
            <div class="row" v-if="store_id">
              <div class="col text-center body-ok">
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="cur_page = 2"
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="cur_page == 2" class="row">
          <div class="col text-center">
            <b-form>
              <b-form-input
                id="input-firstname"
                v-model="formdata.firstname"
                type="text"
                class="inputbox"
                placeholder="First Name / 名"
                required
              ></b-form-input>
              <b-form-input
                id="input-lastname"
                v-model="formdata.lastname"
                type="text"
                class="inputbox"
                placeholder="Last Name / 姓"
                required
              ></b-form-input>
              <b-form-input
                id="input-phone"
                v-model="formdata.phone"
                type="text"
                class="inputbox"
                placeholder="Phone Number / 电话号码"
                required
              ></b-form-input>
              <b-form-select v-model="formdata.party" class="inputbox">
                <b-form-select-option value="1">1</b-form-select-option>
                <b-form-select-option value="2">2</b-form-select-option>
                <b-form-select-option value="3">3</b-form-select-option>
                <b-form-select-option value="4">4</b-form-select-option>
                <b-form-select-option value="5">5</b-form-select-option>
                <b-form-select-option value="6">6</b-form-select-option>
                <b-form-select-option value="7">7</b-form-select-option>
                <b-form-select-option value="8">8</b-form-select-option>
                <b-form-select-option value="9">9</b-form-select-option>
                <b-form-select-option value="9+">9+</b-form-select-option>
              </b-form-select>
              <b-button
                class="btn btn-primary inputbox"
                variant="primary"
                @click="submit_form"
                >Submit</b-button
              >
            </b-form>
          </div>
        </div>
        <div v-if="cur_page == 3" class="row">
          <div class="col">
            <div class="row">
              <div class="col text-left">
                <br />
                Your cooperation is appreciated!
                <br /><br />
                感谢您的合作！
              </div>
            </div>
            <!--div class="row">
              <div class="col text-center body-ok">
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="close_window"
                >
                  OK
                </button>
              </div>
            </div-->
          </div>
        </div>
      </div>
      <div class="fluid-container text-center footerdiv">
        <img class="footerlogo" src="./assets/logo.jpg" />
        Powered By PosKing
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {
      store_id: 0,
      store_name: "",
      cur_page: 1,
      formdata: {
        firstname: "",
        lastname: "",
        phone: "",
        party: 2,
      },
    };
  },
  methods: {
    async submit_form(e) {
      e.preventDefault();
      let that = this;
      var bodyFormData = new FormData();
      bodyFormData.append('store_id', this.store_id);
      for (var key in this.formdata) {
        bodyFormData.append(key, this.formdata[key]);
      }
      this.$axios
        .post("/posapi/coviduser", bodyFormData)
        .then(function (response) {
          if (response.status == 200) {
            var rt = response.data;
            if (rt.errCode == 0) {
              that.cur_page = 3;
            } else if (rt.msg != undefined) {
              alert(rt.msg);
            }
          }
        })
        .catch(function (error) {
          alert('Some thing wrong, Please try it later.');
          console.log("error", error);
        });
    },
    async init_data() {
      let that = this;
      this.$axios
        .get("/posapi/covidstore/" + this.store_id)
        .then(function (response) {
          // console.log("response1 ", response);
          if (response.status == 200) {
            that.store_name = response.data;
          } else {
            that.store_id = 0;
          }
        })
        .catch(function (error) {
          that.store_id = 0;
          console.log("error", error);
        });
    },
    findGetParameter(parameterName) {
      var result = null, tmp = [];
      location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
          tmp = item.split("=");
          if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
      return result;
    },
    close_window() {
      window.close();
    },
  },
  mounted() {
    var store_id = this.findGetParameter('s');
    if (store_id) {
      this.store_id = store_id;
      this.init_data();
    }
    this.windowHeight = window.innerHeight;
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;
  height: 100%;
}
.header-title {
  background-color: #52b907 !important;
}
.body-ok {
  padding-top: 2rem;
}
.inputbox {
  margin-top: 2rem !important;
}
.bodydiv {
  height: 100%;
  overflow: auto;
  padding-bottom: 3rem;
}
.footerdiv {
  display: block;
  width: 100%;
  height: 3rem;
  position: fixed;
  bottom: 0px;
  background-color: #f0f0f0;
  align-items: center; /*Aligns vertically center */
  justify-content: center; /*Aligns horizontally center */
  padding-top: 1rem;
}
.footerlogo {
  width: 4rem;
}
</style>
